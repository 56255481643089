import dynamic from 'next/dynamic';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';

import '../common/common.css';
import './trusted.css';
const Slider = dynamic(() => import('react-slick'));

export default function TrustedCompanies({ Trusted500, writetous }) {
  const settings = {
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    arrows: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          adaadaptiveHeight: true,
        },
      },
    ],
  };
  const settingss = {
    rtl: true,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    arrows: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      {Trusted500?.titleEditor && (
        <div className="xl:mb-[34px] md:mb-[30px] mb-[20px] max-w-[1100px]">
          {Trusted500?.title && (
            <p
              className={`text-[#1A202C] md:text-[24px] text-[18px] font-[400] flex flex-wrap items-center ${writetous && 'text-[#fff]'}`}
            >
              {Trusted500?.title}
              <span className="bg-[#000000] w-[60px] h-[2px] block ml-3"></span>
            </p>
          )}
          {Trusted500?.titleEditor && (
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className={`md:font-[400] font-[800] xl:text-[50px] md:text-[32px] text-[25px] title_hd md:leading-tight leading-9 tracking-[-1px] ${writetous && 'text-[#fff]'}`}
              source={Trusted500?.titleEditor}
            >
              {Trusted500?.titleEditor}
            </ReactMarkdown>
          )}
        </div>
      )}
      <Slider {...settings} className="trusted-img mb-[5px]">
        {Trusted500?.Companies?.map((data, key) => (
          <div key={key}>
            <div
              className={`flex items-center justify-center ${writetous ? 'bg-[#38383880]' : 'bg-[#F9F9FB]'} border border-[#0000001A] md:py-5 ${data?.text1 ? 'h-[120px]' : 'md:h-[150px] h-[70px]'}`}
            >
              {data?.image?.data?.attributes?.url ? (
                <CloudinaryImage
                  backendImgUrl={data?.image?.data?.attributes?.url}
                  alt={data?.image?.data?.attributes?.alternativeText}
                  type="smallimg"
                  className={'h-full md:w-10/12 w-28 object-contain'}
                />
              ) : (
                <p className="text-[#202020] xl:text-[30px] lg:text-[24px] md:text-[20px] text-[16px] font-[700]">
                  {data?.text1}
                </p>
              )}
            </div>
          </div>
        ))}
      </Slider>
      <Slider {...settingss} className="trusted-img">
        {Trusted500?.Companies?.map((data, key) => (
          <div key={key}>
            <div
              className={`flex items-center justify-center ${writetous ? 'bg-[#38383880]' : 'bg-[#F9F9FB]'} border border-[#0000001A] py-5 ${data?.text1 ? 'h-[120px]' : 'md:h-[150px] h-[70px]'}`}
            >
              {data?.image?.data?.attributes?.url ? (
                <CloudinaryImage
                  backendImgUrl={data?.image?.data?.attributes?.url}
                  alt={data?.image?.data?.attributes?.alternativeText}
                  type="smallimg"
                  className={'h-full md:w-10/12 w-28 object-contain'}
                />
              ) : (
                <p className="text-[#202020] xl:text-[30px] lg:text-[24px] md:text-[20px] text-[16px] font-[700]">
                  {data?.text1}
                </p>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </Container>
  );
}
